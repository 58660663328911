import { CoreActivityPageProps } from "@/src/page-data/classes";
import { ClassPageVisitHistory } from "@outschool/ui-components-website";
// This file is not yet translated.
/* eslint-disable i18next/no-literal-string */
import React from "react";

import CoreNotEnrolledView from "./CoreNotEnrolledView";

export default function ActivityDetailPage({
  activity,
  ...props
}: CoreActivityPageProps) {
  ClassPageVisitHistory.pushToTop(activity.uid);

  return (
    <CoreNotEnrolledView
      activity={activity}
      leader={activity.leader}
      showDetails
      {...props}
    />
  );
}
